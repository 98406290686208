import { Container } from '@/ui/components/Container/Container';
import { useMediaQuery } from '@/ui/hooks/useMediaQuery';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { Accordion } from '../../components/Accordion/Accordion';
import styles from './FAQ.module.scss';

export const questions = [
  {
    question: 'Czy na pewno dam sobie radę?',
    answer:
      'Hackathon to miejsce, z którego wartość wyciągną zarówno doświadczeni programiści, jak i osoby wchodzące do branży. Właśnie dlatego, jest on taki skuteczny w procesie rozwoju. Jeżeli masz wątpliwości, to sprawdź nasze bezpłatne warsztaty, które pomogą Ci przygotować się do wydarzenia.',
  },
  {
    question: 'Co, jeżeli nie mam zespołu?',
    answer:
      'Nie ma problemu! Nasz system matchmakingu pomoże Ci znaleźć zespół, który będzie pasował do Twoich umiejętności i specjalizacji.',
  },
  {
    question: 'Kto będzie oceniał projekty?',
    answer:
      'Projekty będą oceniane przez gościnny zespół ekspertów w 3 obszarach. Tech & AI, Design oraz Business. W zależności od tematyki hackathonu jury może się różnić.',
  },
  {
    question: 'Co, jeżeli nie dogadam się z moim zespołem?',
    answer:
      'W takiej sytuacji możesz zgłosić się do nas z prośbą o zmianę zespołu. Postaramy się znaleźć dla Ciebie nowych partnerów.',
  },
  {
    question: 'Co, jeżeli nie zdążę zrealizować projektu w terminie?',
    answer:
      'W takiej sytuacji, projekt nie zostanie będzie mógł zostać oceniony oraz nie zostaną przydzielone punkty za ukończenie. Jednakże, nadal będziesz mógł go dokończyć w dogodnym dla siebie czasie.',
  },
  {
    question: 'Co, jeżeli będę musiał zrezygnować w trakcie?',
    answer:
      'W takiej sytuacji bierzesz na siebie tłumaczenie się przed resztą zespołu :-)',
  },
];

export const FAQ = () => {
  const { isMobile, isDesktop } = useMediaQuery();
  const { t } = useTranslation(I18nNamespaces.Homepagev2);
  return (
    <section id="faq" className={styles.container}>
      {isMobile && (
        <Image
          width={100}
          height={100}
          src={'/images/landing-v2/homepage-faq-mobile.webp'}
          alt={'faq image'}
        />
      )}
      <Container>
        <div className={styles.faqWrapper}>
          <div className={styles.leftSection}>
            <div className={styles.titleWrapper}>
              <h2 className={styles.title}>
                {t('component.contact.title')}
              </h2>
              <h4 className={styles.subtitle}>
                {t('component.contact.description')}
              </h4>
              <div className={styles.card}>
                <div className={styles.avatar}></div>
                <div>
                  <span className={styles.name}>
                    Jakub @sumick Wąsowski
                  </span>
                  <p className={styles.email}>jakub@nerdbord.io</p>
                </div>
              </div>
            </div>
            {isDesktop && <div className={styles.image}></div>}
          </div>
          <div className={styles.rightSection}>
            {questions.map((item, index) => (
              <Accordion
                key={index}
                title={item.question}
                content={item.answer}
              />
            ))}
          </div>
        </div>
      </Container>
      {isMobile && (
        <Image
          width={374}
          height={214}
          src={'/images/landing-v2/homepage-faq-mobile2.webp'}
          alt={'faq image'}
        />
      )}
    </section>
  );
};
