import { ButtonIcon } from '@/components/ButtonIcon/ButtonIcon';
import { ArrowLeft } from '@/components/Icons/ArrowLeft';
import { ArrowRight } from '@/components/Icons/ArrowRight';
import {
  UserReview,
  UserReviewProps,
} from '@/components/UserReview/UserReview';
import { Colors } from '@/styles/colors';
import React, { useEffect, useRef, useState } from 'react';
import * as carouselStyles from './UserReviewCarousel.styles';

interface UserReviewCarouselProps {
  reviews: UserReviewProps[];
  onSetEvaluationPublic?(
    evaluationId: string
  ): (isPublic: boolean) => void;
}

export const UserReviewCarousel: React.FC<UserReviewCarouselProps> =
  ({ reviews, onSetEvaluationPublic }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const isHoveredRef = useRef(false);

    const handlePrev = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : reviews.length - 1
      );
    };

    const handleNext = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex < reviews.length - 1 ? prevIndex + 1 : 0
      );
    };

    useEffect(() => {
      const intervalId = setInterval(() => {
        if (!isHoveredRef.current) {
          handleNext();
        }
      }, 4000);

      return () => clearInterval(intervalId);
    }, []);

    const onMouseEnter = () => {
      isHoveredRef.current = true;
    };

    const onMouseLeave = () => {
      isHoveredRef.current = false;
    };
    return (
      <div
        className={carouselStyles.carouselContainer}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <ButtonIcon
          className={carouselStyles.buttonPrev}
          Icon={<ArrowLeft color={Colors.black} />}
          onClick={handlePrev}
        />
        <div className={carouselStyles.carouselWrapper}>
          <div
            className={carouselStyles.reviewList(currentIndex)}
            style={{
              transform: `translateX(-${currentIndex * 100}%)`,
            }} // Inline style for dynamic property
          >
            {reviews.map((review, idx) => (
              <div className={carouselStyles.reviewItem} key={idx}>
                {idx === currentIndex && ( // Render only the active review
                  <UserReview
                    {...review}
                    onSetEvaluationPublic={
                      onSetEvaluationPublic
                        ? onSetEvaluationPublic(review.reviewId)
                        : undefined
                    }
                  />
                )}
              </div>
            ))}
          </div>
        </div>
        <ButtonIcon
          className={carouselStyles.buttonNext}
          Icon={<ArrowRight color={Colors.black} />}
          onClick={handleNext}
        />
      </div>
    );
  };
