import { useMediaQuery } from '@/hooks/useMediaQuery';
import ForWhomLabel from '@/public/images/landing-v2/forwhom-label.webp';
import { Container } from '@/ui/components/Container/Container';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import styles from './ForWhom.module.scss';
import { SpecializationComponent } from './SpecializationComponent/SpecializationComponent';

export const ForWhom = () => {
  const { isMobile, isDesktop } = useMediaQuery();
  const { t } = useTranslation(I18nNamespaces.Homepagev2);
  return (
    <section id="for-whom" className={styles.container}>
      <Image
        className={styles.forWhomLabelImage}
        src={ForWhomLabel}
        alt="Decoration tape"
        width={230}
        height={276}
      />
      <Container>
        <div className={styles.sectionWrapper}>
          <h2 className={styles.title}>{t('home.forwhom.header')}</h2>
          <h4 className={styles.subtitle}>
            {t('home.forwhom.subheader')}
          </h4>
          <div className={styles.specializationWrapper}>
            <SpecializationComponent
              number={1}
              title={t('home.forwhom.specialization1.title')}
              description={t('home.forwhom.specialization1.text')}
              numberPosition={'top'}
            />
            <SpecializationComponent
              number={2}
              title={t('home.forwhom.specialization2.title')}
              description={t('home.forwhom.specialization2.text')}
              numberPosition={isMobile ? 'left' : 'top'}
            />
            <SpecializationComponent
              number={3}
              title={t('home.forwhom.specialization3.title')}
              description={t('home.forwhom.specialization3.text')}
              numberPosition={'top'}
            />
            <SpecializationComponent
              number={4}
              title={t('home.forwhom.specialization4.title')}
              description={t('home.forwhom.specialization4.text')}
              numberPosition={isMobile ? 'left' : 'top'}
            />
          </div>
        </div>
      </Container>
    </section>
  );
};
