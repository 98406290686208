import { MediaQuery, mq } from '@/styles/mediaQueries';
import { css } from '@emotion/css';

export const carouselContainer = css({
  position: 'relative', // This is important for absolute positioning of the children
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between', // Adjust as needed for your design
  // Add any other styles for the container
});

export const buttonPrev = css({
  position: 'absolute',
  left: -15,
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 1,
  // Style the button as needed, this is an example
  [mq(MediaQuery.DesktopSmall)]: {
    // left: -26,
  },
});

export const buttonNext = css({
  position: 'absolute',
  right: -15,
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 1,
  [mq(MediaQuery.DesktopSmall)]: {
    // right: -26,
  },
});

export const carouselWrapper = css({
  overflow: 'hidden',
  width: '100%',
  maxWidth: 860,
});

export const reviewList = (currentIndex: number) =>
  css({
    display: 'flex',
    // No need to set width to a percentage of totalReviews anymore
    // as we are only showing one review at a time
    transition: 'transform 0.6s ease-in-out',
  });

export const reviewItem = css({
  width: '100%', // Each item is full width of the carouselWrapper
  flexShrink: 0,
  boxSizing: 'border-box',
  [mq(MediaQuery.DesktopSmall)]: {
    width: '100%', // or some other value if you want to show more than one review item on larger screens
  },
});
