import { MediaQuery, mq } from '@/ui/styles/mediaQueries';
import { css } from '@emotion/css';

export const container = (imagePosition: string) =>
  css({
    position: 'relative',
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: '3.5rem 1.5rem 3.5rem',
    [mq(MediaQuery.Mobile)]: {
      width: '100%',
      padding: '3rem 1.5rem 3rem',
    },
  });

export const text = css({
  width: '100%',
  maxWidth: '360px',
  display: 'flex',
  justifyContent: 'center',
  fontFamily: 'Inter',
  fontSize: '22px',
  fontStyle: 'normal',
  fontWeight: 600,
  letterSpacing: '-1.32px',
  marginTop: '1.5rem',
  background:
    'var(--gradient-2, linear-gradient(97deg, #c32900 -5.49%, #fb5500 68.62%))',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  [mq(MediaQuery.Mobile)]: {
    position: 'relative',
    fontSize: '18px',
    letterSpacing: '-1.28px',
    top: '0',
    left: '0',
    display: 'flex',
    alignSelf: 'center',
    height: 'auto',
    padding: '0 5px',
    lineHeight: '130%',
  },
});
