import { Container } from '@/ui/components/Container/Container';
import { useMediaQuery } from '@/ui/hooks/useMediaQuery';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useTranslation } from 'next-i18next';
import styles from './AboutUs.module.scss';
import { AwardComponent } from './AwardComponent/AwardComponent';
import { MediaComponent } from './MediaComponent/MediaComponent';

export const AboutUs = () => {
  const { isMobile, isDesktop } = useMediaQuery();
  const { t } = useTranslation(I18nNamespaces.Homepagev2);
  return (
    <div className={styles.container} id="about-us">
      <Container>
        <div className={styles.header}>
          <h2 className={styles.title}>{t('home.aboutUs.header')}</h2>
          <h4 className={styles.subtitle}>
            {t('home.aboutUs.subheader')}
          </h4>
        </div>
        <div className={styles.wrapper}>
          <MediaComponent
            imageUrl={
              '/images/landing-v2/homepage-about-us-media1.webp'
            }
            imageDesc={t('home.aboutUs.card1.imageDesc')}
            description={t('home.aboutUs.card1.description')}
            title={t('home.aboutUs.card1.title')}
            youtubeUrl={'https://www.youtube.com/watch?v=6LhU4jjgx3I'}
          />
          <MediaComponent
            imageUrl={
              '/images/landing-v2/homepage-about-us-media2.webp'
            }
            imageDesc={t('home.aboutUs.card2.imageDesc')}
            description={t('home.aboutUs.card2.description')}
            title={t('home.aboutUs.card2.title')}
            youtubeUrl={'https://www.youtube.com/watch?v=eNlX_pfgJ2g'}
          />
          {isDesktop && (
            <>
              <div className={styles.award1Wrapper}>
                <AwardComponent
                  imageUrl={
                    '/images/landing-v2/homepage-createwithai-award1.webp'
                  }
                  text={t('home.aboutUs.award1.text')}
                />
              </div>
              <div className={styles.award2Wrapper}>
                <AwardComponent
                  imageUrl={
                    '/images/landing-v2/homepage-createwithai-award2.webp'
                  }
                  text={t('home.aboutUs.award2.text')}
                />
              </div>
            </>
          )}
        </div>
      </Container>
    </div>
  );
};
