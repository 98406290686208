// Assuming you have a design token file that includes colors, spacings, etc.
import { Colors } from '@/styles/colors';
import { MediaQuery, mq } from '@/styles/mediaQueries';
import { Spacings } from '@/styles/spacings';
import { BorderRadius, FontSize, FontWeight } from '@/styles/tokens';
import { css } from '@emotion/css';

export const reviewContainer = css({
  display: 'flex',
  alignItems: 'flex-start',
  backgroundColor: Colors.white,
  borderRadius: BorderRadius.S2,
  border: `1px solid ${Colors.gray100}`,
  padding: Spacings.S24,
  minHeight: 170,
  gap: Spacings.S16,
  [mq(MediaQuery.DesktopSmall)]: {
    flexDirection: 'column',
    // alignItems: 'center',
    padding: Spacings.S32,
  },
});

export const elementsGroup = css({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  marginBottom: Spacings.S16,
  [mq(MediaQuery.DesktopSmall)]: {
    gap: Spacings.S4,
  },
});

export const userNameStyle = css({
  marginRight: Spacings.S22,
  fontSize: FontSize.S16,
  fontWeight: FontWeight.S500,
  ':hover': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  [mq(MediaQuery.DesktopSmall)]: {
    marginRight: 0,
  },
});

export const avatarGroup = css({
  display: 'flex',
  alignItems: 'center',
  gap: Spacings.S8,
});

export const roleStyle = css({
  fontSize: FontSize.S16,
  color: Colors.black,
  fontWeight: FontWeight.S400,
  paddingRight: Spacings.S12,
  marginRight: Spacings.S12,
  borderRight: `1px solid ${Colors.gray100}`,
});

export const ratingStyle = css({
  display: 'flex',
  alignItems: 'center',
  marginRight: Spacings.S4,
  borderRadius: '60px',
  gap: Spacings.S4,
  padding: '2px 8px',
  background: Colors.gray100,
});

export const rating = css({
  color: Colors.orange500,
  marginLeft: Spacings.S4,
});

export const star = (isFilled: boolean) =>
  css({
    color: isFilled ? Colors.orange600 : Colors.orange600,
    marginRight: '2px', // Adjust spacing between stars
  });

export const reviewTextStyle = css({
  color: Colors.gray500,
  fontSize: FontSize.S16,
  lineHeight: 1.5,
  maxWidth: 475,
  span: {
    opacity: 0.3,
  },
  // Include other text styles here
});
