import { IconDefault } from '@/components/Icons/types';

export const ArrowLeft = ({ scale = 100, ...props }: IconDefault) => (
  <svg
    onClick={props.onClick}
    width={(24 * scale) / 100}
    height={(24 * scale) / 100}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 12L3 12"
      stroke={props.color || '#52525B'}
      strokeLinecap="round"
    />
    <path
      d="M9 6L3 12L9 18"
      stroke={props.color || '#52525B'}
      strokeLinecap="round"
    />
  </svg>
);
