import { css, cx } from '@emotion/css';
import React, { useRef, useState } from 'react';

import { ButtonSize } from '@/components/Button/Button';
import { Colors } from '@/styles/colors';

interface ButtonIconProps {
  size?: ButtonSize;
  disabled?: boolean;
  bgColor?: Colors;
  Icon: React.ReactElement;
  className?: string;
  onClick?(e?: React.MouseEvent): void;
}

export const ButtonIcon = ({
  Icon,
  size = ButtonSize.large,
  onClick,
  disabled,
  bgColor = Colors.white, // Default to white
  className = '',
  ...rest
}: ButtonIconProps) => {
  const [isPressed, setPressed] = useState(false);
  const ref = useRef(null);

  const handleRelease = () => {
    setPressed(false);
  };

  const handlePress = (e: React.MouseEvent) => {
    onClick && onClick(e);
  };

  const baseStyles = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid',
    cursor: 'pointer',
    transition: 'all 0.2s',
  });

  const sizeStyles = css({
    width: size === ButtonSize.small ? '2rem' : '2.25rem',
    height: size === ButtonSize.small ? '2rem' : '2.25rem',
  });

  const bgColorStyles = css({
    backgroundColor: !isPressed ? bgColor : '#f3f4f6', // bg-gray-100 equivalent
  });

  const borderStyles = css({
    borderColor: !isPressed ? '#e5e7eb' : '#9ca3af', // border-gray-200 and border-gray-400 equivalents
  });

  const opacityStyles = css({
    opacity: !isPressed ? 1 : 0.7,
  });

  const hoverStyles = css({
    '&:hover': {
      borderColor: '#9ca3af', // border-gray-400 equivalent
    },
  });

  return (
    <button
      ref={ref}
      onClick={handlePress}
      onMouseUp={handleRelease}
      onMouseLeave={handleRelease}
      disabled={disabled}
      className={cx(
        baseStyles,
        sizeStyles,
        bgColorStyles,
        borderStyles,
        opacityStyles,
        hoverStyles,
        className
      )}
      {...rest}
    >
      {Icon}
    </button>
  );
};
