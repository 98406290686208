export enum FontSize {
  normal = '1rem',
  small = '0.875rem',
  tiny = '0.75rem',
  big = '1.5rem',
  S8 = '8px',
  S10 = '10px',
  S12 = '12px',
  S14 = '14px',
  S16 = '16px',
  S18 = '18px',
  S20 = '20px',
  S24 = '24px',
  S26 = '26px',
  S28 = '28px',
  S30 = '30px',
  S32 = '32px',
  S36 = '36px',
  S38 = '38px',
  S40 = '40px',
  S46 = '46px',
  S50 = '50px',
  S52 = '52px',
  S54 = '54px',
  S64 = '64px',
  S70 = '70px',
}

export enum FontWeight {
  S300 = 300,
  S400 = 400,
  S500 = 500,
  S600 = 600,
  S700 = 700,
  black = 900,
}

export enum FontFamily {
  SFD = 'SF Pro Display',
  SFR = 'SF Pro Rounded',
  Inter = 'Inter',
  Sora = 'Sora',
  RobotoMono = "'Roboto Mono', monospace;",
}

export enum FontLineHeight {
  H16 = '16px',
  H19 = '19px',
  H21 = '21px',
  H24 = '24px',
  H27 = '27px',
  H31 = '31px',
  H34 = '34px',
  H38 = '38px',
  H42 = '42px',
  H47 = '47px',
  H52 = '52px',
  H60 = '60px',
  H64 = '64px',
  H77 = '77px',
}

export enum BoxShadow {
  xxs = 'inset 0px 0px 4px rgba(0, 0, 0, 0.08)',
  xs = 'inset 0px 2px 4px rgba(0, 0, 0, 0.08)',
  s = '0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)',
  m = '0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)',
  l = '0px 4px 4px rgba(0, 0, 0, 0.25)',
  xl = '0px 25px 50px rgba(0, 0, 0, 0.25)',
  xxl = '0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04)',
}

export enum BorderRadius {
  S2 = '2px',
  S4 = '4px',
  S10 = '10px',
  S20 = '20px',
  S32 = '32px',
  S40 = '40px',
}
