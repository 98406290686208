import { DEFAULT_ANIMATION_TIME } from '@/styles/animations';
import { Colors } from '@/styles/colors';
import { Spacings } from '@/styles/spacings';
import { BorderRadius, FontSize, FontWeight } from '@/styles/tokens';
import { css } from '@emotion/css';

export const checkboxLabel = css({
  display: 'flex',
  alignItems: 'center',
  minWidth: 18,
  flexShrink: 0,
  fontSize: FontSize.S12,
  fontWeight: FontWeight.S400,
  color: Colors.gray500,
  gap: Spacings.S8,
  a: {
    color: Colors.orange600,
  },
});

export const checkbox = (checked = false) =>
  css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '18px',
    height: '18px',
    padding: '2.5px',
    cursor: 'pointer',
    transitionDuration: DEFAULT_ANIMATION_TIME,
    background: checked ? Colors.orange600 : Colors.white,
    border: `1px solid ${checked ? 'transparent' : Colors.gray400}`,
    borderRadius: BorderRadius.S2,
    '&:hover': {
      border: `1px solid ${Colors.orange600}`,
    },
  });

export const error = css({
  display: 'block',
  color: Colors.red,
  fontSize: FontSize.S12,
  fontWeight: FontWeight.S400,
  marginTop: Spacings.S4,
});
