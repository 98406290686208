import Head from 'next/head';

interface LayoutProps {
  pageTitle: string;
  metaDesc: string;
  ogImageSrc?: string | null;
}

export const PageHead = (props: LayoutProps) => (
  <Head>
    <title>{props.pageTitle}</title>
    <meta name="description" content={props.metaDesc} />
    <meta property="og:title" content={props.pageTitle} />
    <meta property="og:description" content={props.metaDesc} />
    {props.ogImageSrc && (
      <meta property="og:image" content={props.ogImageSrc} />
    )}
    {props.ogImageSrc && (
      <meta property="og:image:url" content={props.ogImageSrc} />
    )}
    <meta property="og:image:alt" content={props.metaDesc} />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
    />
    <link rel="icon" href="/favicon/favicon.ico" />
  </Head>
);
