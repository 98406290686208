import Image from 'next/image';
import styles from './AwardComponent.module.scss';

interface AwardCompoentnProps {
  imageUrl: string;
  text: string;
}
export const AwardComponent = ({
  imageUrl,
  text,
}: AwardCompoentnProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Image
          className={styles.image}
          width={100}
          height={26}
          src={imageUrl}
          alt={'award image'}
        />
        <span className={styles.text}>{text}</span>
      </div>
    </div>
  );
};
