import { useMediaQuery } from '@/ui/hooks/useMediaQuery';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useMemo } from 'react';
import styles from './OurPartners.module.scss';
import { PartnerLogo } from './PartnerLogo';

export const OurPartners = () => {
  const { isMobile, isDesktop } = useMediaQuery();
  const { t } = useTranslation(I18nNamespaces.Homepagev2);

  const partners = useMemo(
    () => [
      {
        url: 'https://confrontjs.pl/',
        logo: '/images/partners/confrontjs.svg',
        width: isDesktop ? 142 : 142,
        height: isDesktop ? 123 : 123,
      },
      {
        url: 'https://devhunting.co',
        logo: '/images/partners/gdh-logo.webp',
        width: isDesktop ? 216 : 264,
        height: isDesktop ? 35 : 43,
      },
      {
        url: 'https://bruxa.co',
        logo: '/images/partners/bruxa-logo.webp',
        width: isDesktop ? 164 : 164,
        height: isDesktop ? 33 : 33,
      },
      {
        url: 'https://warsawjs.com/',
        logo: '/images/partners/warsawjs.svg',
        width: isDesktop ? 90 : 90,
        height: isDesktop ? 123 : 123,
      },
      {
        url: 'https://profile.justjoin.it/profile?utm_source=nerdboard&utm_medium=influ-nerdboard&utm_campaign=matchmaking',
        logo: '/images/partners/jjit-white.jpeg',
        width: isDesktop ? 123 : 123,
        height: isDesktop ? 123 : 123,
      },
      {
        url: 'https://crossweb.pl/',
        logo: '/images/partners/crossweb.svg',
        width: isDesktop ? 206 : 164,
        height: isDesktop ? 55 : 43,
      },
    ],
    [isDesktop]
  );

  return (
    <div className={styles.container}>
      <Image
        className={styles.image}
        width={444}
        height={560}
        src={'/images/landing-v2/homepage-ourpartners-image.webp'}
        alt={'decoration'}
      />
      <div className={styles.wrapper}>
        <h2 className={styles.title}>
          {t('home.ourpartners.title')}
        </h2>
        <span className={styles.subtitle}>
          {t('home.ourpartners.subtitle')}
        </span>
        <div className={styles.partnersWrapper}>
          {partners.map((partner) => (
            <PartnerLogo
              key={partner.url}
              imageUrl={partner.logo}
              width={partner.width}
              height={partner.height}
              url={partner.url}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
