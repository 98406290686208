import withApollo, {
  createSsrApolloClient,
} from '@/lib/apollo-graphql/withApollo';
import { Layout } from '@/ui/landing-v2/layout/Layout';
import { AboutUs } from '@/ui/landing-v2/sections/homepage/AboutUs/AboutUs';
import { CreateWithAI } from '@/ui/landing-v2/sections/homepage/CreateWithAI/CreateWithAI';
import { ForWhom } from '@/ui/landing-v2/sections/homepage/ForWhom/ForWhom';
import { Hero } from '@/ui/landing-v2/sections/homepage/Hero/Hero';
import { Impressions } from '@/ui/landing-v2/sections/homepage/Impressions/Impressions';
import { OurPartners } from '@/ui/landing-v2/sections/homepage/OurPartners/OurPartners';
import { WhyParticipate } from '@/ui/landing-v2/sections/homepage/WhyParticipate/WhyParticipate';
import { authUser } from '@/utils/auth';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { UserEntity } from '@nerdbord/graphql-api';
import { GetServerSidePropsContext } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

interface HomeProps {
  user: UserEntity | null;
}

function Home(props: HomeProps) {
  return (
    <Layout
      pageTitle={'Nerdbord - nextgen AI virtual hackathon platform'}
      metaDesc={
        'Nerdbord is a nextgen AI virtual hackathon platform. Create with AI, win prizes, and learn from the best. Join the next generation of hackathons.'
      }
      ogImageSrc={'/images/nb-og-image.jpg'}
      user={props.user}
    >
      <Hero />
      <ForWhom />
      <WhyParticipate />
      <OurPartners />
      <Impressions />
      {/*<CheckRanking />*/}
      <CreateWithAI />
      <AboutUs />
      {/*<CheckWorkshops />*/}
    </Layout>
  );
}

export async function getServerSideProps({
  locale,
  ...context
}: GetServerSidePropsContext) {
  const apolloClient = createSsrApolloClient(context);
  try {
    const user = await authUser(context, apolloClient);
    return {
      props: {
        user,
        ...(locale &&
          (await serverSideTranslations(locale, [
            I18nNamespaces.Homepagev2,
          ]))),
      },
    };
  } catch (err) {
    return {
      props: {
        ...(locale &&
          (await serverSideTranslations(locale, [
            I18nNamespaces.Homepagev2,
          ]))),
        user: null,
      },
    };
  }
}

export default withApollo(Home);
