import { Fade } from '@/components/Transitions/Fade';
import Link from 'next/link';
import { useEffect, useId, useState } from 'react';
import * as styles from './InputCheckbox.styles';

interface InputCheckboxProps {
  id?: string;
  label?: string;
  checked: boolean;
  onChange(
    checked: boolean,
    event?: React.ChangeEvent<HTMLInputElement>
  ): void;
  linkLabel?: string;
  linkHref?: string;
  disabled?: boolean;
  error?: string;
  name?: string;
  onBlur?: () => void;
}

export const InputCheckbox = (props: InputCheckboxProps) => {
  const [isChecked, setIsChecked] = useState(props.checked);

  const generatedId = useId();

  const inputId = props.id || generatedId;

  useEffect(() => {
    setIsChecked(props.checked);
  }, [props.checked]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (props.disabled) return;
    event.stopPropagation();
    setIsChecked(!isChecked);

    props.onChange(!isChecked, event);
  };

  return (
    <>
      <label htmlFor={inputId} className={styles.checkboxLabel}>
        <div className={styles.checkbox(isChecked)}>
          <Fade in={isChecked}>
            <Checked />
          </Fade>
          <input
            name={props.name}
            onChange={handleChange}
            disabled={props.disabled}
            checked={isChecked}
            id={inputId}
            type="checkbox"
            hidden
            onBlur={props.onBlur}
          />
        </div>
        <div>
          {props.label}
          {props.linkHref && (
            <Link target={'_blank'} href={props.linkHref}>
              {' '}
              {props.linkLabel}
            </Link>
          )}
        </div>
      </label>
      <span className={styles.error}>{props.error}</span>
    </>
  );
};

export const Checked = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 6.5L5 10.5L11 1"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
