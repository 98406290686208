import styles from './SquareComponent.module.scss';

interface SquareComponentProps {
  title: string;
  description: string;
}

export const SquareComponent = ({
  title,
  description,
}: SquareComponentProps) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.text}>{description}</p>
    </div>
  );
};
