import { css } from '@emotion/css';
import { PropsWithChildren } from 'react';

const containerStyle = css({
  position: 'relative',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: '1280px', // max-w-screen-xl equivalent
  paddingLeft: '1.5rem', // px-6
  paddingRight: '1.5rem', // px-6
  '@media (min-width: 768px)': {
    paddingLeft: '1rem', // md:px-4
    paddingRight: '1rem', // md:px-4
  },
});

export const Container = (props: PropsWithChildren<{}>) => (
  <div className={containerStyle}>{props.children}</div>
);
