import Image from 'next/image';
import styles from './MediaComponent.module.scss';

interface MediaComponentProps {
  imageUrl: string;
  imageDesc: string;
  description: string;
  title: string;
  youtubeUrl: string;
}

export const MediaComponent = ({
  imageUrl,
  imageDesc,
  description,
  title,
  youtubeUrl,
}: MediaComponentProps) => {
  return (
    <a
      href={youtubeUrl}
      className={styles.container}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={styles.imageWrapper}>
        <Image
          className={styles.image}
          width={386}
          height={238}
          src={imageUrl}
          alt={'media image'}
        />
        <span className={styles.imageDesc}>{imageDesc}</span>
      </div>
      <p className={styles.description}>{description}</p>
      <span className={styles.title}>{title}</span>
    </a>
  );
};
