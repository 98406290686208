import { Colors } from '@/styles/colors';
import { css, keyframes } from '@emotion/css';

export const DEFAULT_ANIMATION_TIME = '0.25s';

export enum Animations {
  colorChange,
  vibrate,
  pulsate,
  slideInTop,
  rotate,
}

export const colorChange = (animationDuration: string) =>
  css({
    animation: `${colorChangeKeyframes} ${animationDuration} linear infinite alternate both`,
  });

export const rotate = (animationDuration: string) =>
  css({
    animation: `${rotateKeyframes} ${animationDuration} linear infinite both`,
  });

export const vibrate = (animationDuration: string) =>
  css({
    animation: `${vibrateKeyframes} ${animationDuration} linear infinite both`,
  });

export const slideInTop = (animationDuration: string) =>
  css({
    animation: `${slideInTopKeyframes} ${animationDuration} .7s linear both`,
  });

export const fadeInFromTop = (animationDuration: string) =>
  css({
    animation: `${fadeInFromTopKeyframes} ${animationDuration} ease-in-out both`,
  });

export const slideInFromTop = (animationDuration: string) =>
  css({
    animation: `${slideInFromTopKeyframes} ${animationDuration} linear both`,
  });

export const fadeInFromRight = (animationDuration: string) =>
  css({
    animation: `${fadeInFromRightKeyframes} ${animationDuration} ease-in-out both`,
  });

export const fadeInFromBottom = (animationDuration: string) =>
  css({
    animation: `${fadeInFromBottomKeyframes} ${animationDuration} ease-in-out both`,
  });

const colorChangeKeyframes = keyframes(`  
  0% {
    background: ${Colors.persianBlue},
  }
  100% {
    background: ${Colors.corn}
  }
`);

export const rotateKeyframes = keyframes(`
0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  `);

export const slideInFromTopKeyframes = keyframes(`
 0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }`);

export const fadeInFromTopKeyframes = keyframes(`
  0%{ -webkit-transform:translateY(-50px); transform:translateY(-50px);opacity:0}100%{-webkit-transform:translateY(0);transform:translateY(0);opacity:1}}@keyframes fade-in-top{0%{-webkit-transform:translateY(-50px);transform:translateY(-50px);opacity:0}100%{-webkit-transform:translateY(0);transform:translateY(0);opacity:1}
`);

export const fadeInFromBottomKeyframes = keyframes(`
   0%{-webkit-transform:translateY(50px);transform:translateY(50px);opacity:0}100%{-webkit-transform:translateY(10px);transform:translateY(10px);opacity:1}}@keyframes fade-in-bottom{0%{-webkit-transform:translateY(50px);transform:translateY(50px);opacity:0}100%{-webkit-transform:translateY(10px);transform:translateY(10px);opacity:1}
`);

export const fadeInFromRightKeyframes = keyframes(
  `0%{-webkit-transform:translateX(50px);transform:translateX(50px);opacity:0}100%{-webkit-transform:translateX(0);transform:translateX(0);opacity:1}}@keyframes fade-in-right{0%{-webkit-transform:translateX(50px);transform:translateX(50px);opacity:0}100%{-webkit-transform:translateX(0);transform:translateX(0);opacity:1}`
);

export const slideInTopKeyframes = keyframes(`
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
`);

export const vibrateKeyframes = keyframes(`
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
`);

const pulsateKeyframes = keyframes(`
from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }`);

export const animationsKeyframesCollection = new Map([
  [Animations.vibrate, vibrateKeyframes],
  [Animations.colorChange, colorChangeKeyframes],
  [Animations.pulsate, pulsateKeyframes],
  [Animations.slideInTop, slideInTopKeyframes],
  [Animations.rotate, rotateKeyframes],
]);
