import { MediaQuery, mq } from '@/ui/styles/mediaQueries';
import { css } from '@emotion/css';

export const container = (isOpen: boolean) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    gap: isOpen ? '12px' : '0px',
    padding: '30px 50px',
    background: 'var(--Light-1, #fafafa)',
    [mq(MediaQuery.Mobile)]: {
      padding: '30px 20px',
      width: '100%',
    },
  });

export const header = css({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  cursor: 'pointer',
});

export const title = css({
  color: 'var(--Gray-1, #27272a)',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '120%',
  letterSpacing: '-0.36px',
  [mq(MediaQuery.Mobile)]: {
    fontSize: '16px',
    letterSpacing: '-0.32px',
  },
});

export const content = (isOpen: boolean | undefined) =>
  css({
    color: 'var(--Gray-3, #71717a)',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '130%',
    overflow: 'hidden',
    [mq(MediaQuery.Mobile)]: {
      fontSize: '14px',
    },
  });

export const contentWrapper = (isOpen: boolean | undefined) =>
  css({
    display: 'grid',
    gridTemplateRows: isOpen ? '1fr' : '0fr',
    transition: 'grid-template-rows 500ms',
    height: isOpen ? 'auto' : '1px',
  });
