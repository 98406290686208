import { MediaQuery, mq } from '@/ui/styles/mediaQueries';
import { css } from '@emotion/css';

export const container = (numberPosition: string) =>
  css({
    display: 'flex',
    flexDirection: numberPosition === 'left' ? 'row' : 'column',
    gap: '25px',
  });

export const number = css({
  fontFamily: 'Inter',
  fontSize: '30px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '90%',
  letterSpacing: '-1.8px',
  background:
    'var(--gradient-2, linear-gradient(97deg, #c32900 -5.49%, #fb5500 68.62%))',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
});

export const numberWrapper = css({
  marginLeft: '-20px',
  borderRadius: '50%',
  width: '60px',
  height: '60px',
  backgroundColor: '#ffffff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [mq(MediaQuery.Mobile)]: {
    marginLeft: '0',
  },
});

export const contentWrapper = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '25px',
});

export const title = css({
  fontFamily: 'Inter',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 600,
  // lineHeight: '90%', // 21.6px
  letterSpacing: '-1.44px',
  background:
    'var(--gradient-2, linear-gradient(97deg, #c32900 -5.49%, #fb5500 68.62%))',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
});

export const text = css({
  color: 'var(--Orange-3, #fb923c)',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '120%',
  letterSpacing: '-0.32px',
  maxWidth: '240px',
});
