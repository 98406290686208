import { IconDefault } from '@/components/Icons/types';

export const ArrowRight = ({
  scale = 100,
  ...props
}: IconDefault) => (
  <svg
    width={(24 * scale) / 100}
    height={(24 * scale) / 100}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 12L21 12"
      stroke={props.color || '#FAFAFA'}
      strokeLinecap="round"
    />
    <path
      d="M15 18L21 12L15 6"
      stroke={props.color || '#FAFAFA'}
      strokeLinecap="round"
    />
  </svg>
);

export const ArrowRightTop = ({
  scale = 100,
  ...props
}: IconDefault) => (
  <svg
    width={(20 * scale) / 100}
    height={(20 * scale) / 100}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8337 10.8335V4.16683H9.16699"
      stroke={props.color || '#52525B'}
      strokeLinecap="round"
    />
    <path
      d="M15.8337 4.1665L4.16699 15.8332"
      stroke={props.color || '#52525B'}
      strokeLinecap="round"
    />
  </svg>
);
