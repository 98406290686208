import { css, cx } from '@emotion/css';
import Image from 'next/image';

export interface AvatarProps {
  onClick?(): void;
  imgSrc?: string | null;
  desc: string;
  size?: number;
}

export const Avatar = ({
  size = 34,
  imgSrc,
  desc,
  onClick,
}: AvatarProps) => {
  const baseStyles = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: 'rgba(255, 165, 0, 1)', // bg-orange-500
    overflow: 'hidden',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // shadow-custom equivalent
    cursor: onClick ? 'pointer' : 'default',
  });

  const groupElement = (zIndex: number, right = 10) =>
    css({
      position: 'relative',
      zIndex: zIndex,
      ...(zIndex !== 0 && { right: `${right}px` }),
    });

  return (
    <div
      style={{ width: size, height: size }}
      onClick={onClick}
      className={cx(baseStyles, groupElement(0, 10))}
    >
      {imgSrc && (
        <Image
          width={size}
          height={size}
          src={imgSrc}
          alt={desc || ''}
          className={css({
            width: '100%',
            height: 'auto',
          })}
        />
      )}
    </div>
  );
};
