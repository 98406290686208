import Image from 'next/image';
import Link from 'next/link';

interface PartnerLogoProps {
  imageUrl: string;
  width: number;
  height: number;
  url: string;
}
export const PartnerLogo = ({
  imageUrl,
  width,
  height,
  url,
}: PartnerLogoProps) => {
  return (
    <Link
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      target={'_blank'}
      rel={'noreferrer'}
      href={url}
    >
      <Image
        width={width}
        height={height}
        src={imageUrl}
        alt={'partner logo'}
      />
    </Link>
  );
};
