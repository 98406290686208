import * as styles from './SpecializationComponent.styles';

interface SpecializationComponentProps {
  number: number;
  numberPosition: 'left' | 'top'
  title: string;
  description: string;
}

export const SpecializationComponent = ({
  number,
  title,
  description,
  numberPosition,
}: SpecializationComponentProps) => {
  return (
    <div className={styles.container(numberPosition)}>
      <div className={styles.numberWrapper}>
        <div className={styles.number}>{number}</div>
      </div>
      <div className={styles.contentWrapper}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.text}>{description}</p>
      </div>
    </div>
  );
};
