import { useState } from 'react';
import { MinusIcon } from '../icons/MinusIcon';
import { PlusIcon } from '../icons/PlusIcon';
import * as styles from './Accordion.styles';

interface AccordionProps {
  title: string;
  content: string;
  isAccordionOpen?: boolean;
}

export const Accordion = ({
  title,
  content,
  isAccordionOpen,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(Boolean(isAccordionOpen));

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.container(isOpen)}>
      <div className={styles.header} onClick={toggleAccordion}>
        <span className={styles.title}>{title}</span>
        {isOpen ? <MinusIcon /> : <PlusIcon />}
      </div>
      <div className={styles.contentWrapper(isOpen)}>
        <p className={styles.content(isOpen)}>{content}</p>
      </div>
    </div>
  );
};
