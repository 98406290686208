import { PropsWithChildren } from 'react';
import { Transition } from 'react-transition-group';

const duration = 200;

const defaultStyle = {
  position: 'relative',
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 1,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

interface FadeProps {
  in: boolean;
}

export const Fade = ({
  in: inProp,
  children,
}: PropsWithChildren<FadeProps>) => {
  return (
    <Transition in={inProp} timeout={duration}>
      {(state) => (
        <div
          style={{
            ...defaultStyle,
            //@ts-ignore
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};
