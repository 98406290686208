import { Colors } from '@/styles/colors';
import { Spacings } from '@/styles/spacings';
import { Theme } from '@/styles/themes';
import {
  BorderRadius,
  BoxShadow,
  FontFamily,
  FontLineHeight,
  FontSize,
  FontWeight,
} from '@/styles/tokens';
import { css } from '@emotion/css';

export const buttonPrimary = css({
  background: Colors.gray800,
  color: Colors.white,
  border: `1px solid transparent`,
});

export const buttonPrimaryPurple = css({
  background: Colors.indigo600,
  color: Colors.white,
  border: `1px solid transparent`,
});

export const buttonPrimaryLarge = css({
  fontWeight: FontWeight.S700,
  fontSize: FontSize.S16,
  padding: '16px 48px',
});

export const buttonPrimaryWhite = css({
  background: Colors.white,
  color: Colors.governorBay,
  border: `1px solid ${Colors.gray200}`,
  ':hover': {
    border: `1px solid ${Colors.gray400}`,
    background: Colors.gray800,
    color: Colors.white,
  },
});

export const buttonAnchor = (theme?: Theme, disabled?: boolean) =>
  css({
    display: 'inline-flex',
    color: theme ? theme.font : Colors.governorBay,
    background: 'none',
    opacity: disabled ? 0.3 : 1,
    fontSize: FontSize.S14,
    fontWeight: FontWeight.S400,
    border: 'none',
    minWidth: 'auto',
    padding: 0,
    cursor: 'pointer',
    svg: { marginRight: Spacings.S8 },
    '&:hover': {
      textDecoration: 'underline',
    },
  });

export const buttonSecondary = css({
  transitionDuration: '0s',
  background: Colors.white,
  color: Colors.gray800,
  border: `1px solid ${Colors.gray300}`,
});

export const buttonThemed = (theme: Theme) =>
  css({
    transitionDuration: '0s',
    background: theme.main || Colors.white,
    color: theme.fontAlt || Colors.gray800,
    border: `1px solid ${theme.fontAlt || Colors.gray300}`,
  });

export const buttonThemedHover = (theme: Theme) =>
  css({
    ':hover': {
      background: Colors.white,
      border: `1px solid  ${theme.main}`,
      color: theme.main,
    },
  });

export const buttonTernary = css({
  background: Colors.graySpecial,
  color: Colors.gray50,
  fontWeight: FontWeight.S400,
  border: '1px solid transparent',
});

export const buttonInverted = css({
  background: Colors.gray100,
  color: Colors.gray800,
  fontWeight: FontWeight.S500,
  border: 'none',
  padding: `${Spacings.S8} ${Spacings.S24}`,
  '&:hover': {
    background: Colors.gray800,
    color: Colors.gray100,
  },
});

export const buttonIndigo = css({
  background: 'transparent',
  color: Colors.indigo300,
  fontWeight: FontWeight.S500,
  border: `1px solid ${Colors.indigo300}`,
});

export const buttonIndigoBackground = css({
  background: Colors.indigo300,
  color: Colors.white,
  fontWeight: FontWeight.S500,
  border: `1px solid transparent`,
});

export const buttonOrangeBackground = css({
  background: Colors.orange600,
  color: Colors.white,
  fontWeight: FontWeight.S500,
  border: `1px solid transparent`,
});

export const buttonDisabled = css({
  opacity: 0.3,
  pointerEvents: 'none',
  transitionDuration: '0.3s',
  ':hover': {
    cursor: 'default',
    opacity: 0.3,
  },
});

export const buttonPressed = css({
  background: Colors.gray100,
  border: '1px solid transparent',
  color: Colors.gray800,
  path: {
    fill: Colors.gray800,
  },
});

export const buttonNonPressed = css({
  boxShadow: BoxShadow.s,
});

export const button = (fullWidth = false) =>
  css({
    display: 'flex',
    fontFamily: FontFamily.Inter,
    alignItems: 'center',
    fontWeight: FontWeight.S500,
    justifyContent: 'center',
    lineHeight: FontLineHeight.H19,
    textAlign: 'center',
    cursor: 'pointer',
    width: fullWidth ? '100%' : 'auto',
    minWidth: '120px',
    transitionDuration: '0.2s',
    borderRadius: BorderRadius.S2,
    svg: {
      display: 'block',
      marginLeft: Spacings.S8,
    },
  });

export const defaultButtonHover = css({
  ':hover': {
    background: Colors.white,
    border: `1px solid  ${Colors.gray400}`,
    color: Colors.gray800,
    path: {
      stroke: Colors.gray800,
    },
    circle: {
      stroke: Colors.gray800,
    },
  },
});

export const small = css({
  fontSize: FontSize.S12,
  maxHeight: '38px',
  padding: `10px ${Spacings.S16}`,
  minWidth: 'unset',
});

export const medium = css({
  fontSize: FontSize.S14,
  padding: '14px 24px 14px 24px',
});

export const large = css({
  padding: '16px 36px',
  maxHeight: 50,
  fontSize: FontSize.S16,
});
