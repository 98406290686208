export enum I18nNamespaces {
  Common = 'common',
  Homepage = 'homepage',
  Homepagev2 = 'homepage-v2',
  GroupPage = 'group.page',
  App = 'app',
  AppBoard = 'app.board',
  ProjectModals = 'modals.project',
  RegistrationWizard = 'registration.wizard',
}
