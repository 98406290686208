import { UserReviewCarousel } from '@/components/UserReview/UserReviewCarousel';
import { Container } from '@/ui/components/Container/Container';
import { useMediaQuery } from '@/ui/hooks/useMediaQuery';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useFindHackathonsEvaluations } from '@nerdbord/graphql-api';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import styles from './Impressions.module.scss';

export const Impressions = () => {
  const { isMobile, isDesktop } = useMediaQuery();
  const { projectEvaluations } = useFindHackathonsEvaluations();
  const { t } = useTranslation(I18nNamespaces.Homepagev2);

  const userReviews = projectEvaluations.map((evaluation) => ({
    reviewId: evaluation.id,
    avatar: evaluation.authorAvatarSrc,
    username: evaluation.author,
    githubUsername: evaluation.authorGithubUsername,
    projectName: evaluation.projectName,
    rating: evaluation.rating,
    reviewText: evaluation.comment,
  }));

  return (
    <div className={styles.container} id="reviews">
      {isDesktop && (
        <Container>
          <div className={styles.wrapper}>
            <div className={styles.imgWrapper}>
              <Image
                width={387}
                height={400}
                src={
                  '/images/landing-v2/homepage-impressions-image.webp'
                }
                alt={'decoration'}
              />
            </div>
            <div className={styles.feedbackSection}>
              <div className={styles.header}>
                <h2 className={styles.title}>
                  {t('home.impressions.title')}
                  <p>✌</p>
                </h2>
                <h4 className={styles.subtitle}>
                  {t('home.impressions.subtitle')}
                </h4>
                <div className={styles.feedbackWrapper}>
                  {userReviews.length > 0 ? (
                    <UserReviewCarousel reviews={userReviews} />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Container>
      )}

      {isMobile && (
        <div className={styles.wrapper}>
          <div className={styles.feedbackSection}>
            <div className={styles.header}>
              <Container>
                <h2 className={styles.title}>
                  {t('home.impressions.title')}
                  <p>✌</p>
                </h2>
                <h4 className={styles.subtitle}>
                  {t('home.impressions.subtitle')}
                </h4>
              </Container>
            </div>{' '}
          </div>
          <div className={styles.feedbackWrapper}>
            {userReviews.length > 0 ? (
              <UserReviewCarousel reviews={userReviews} />
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};
