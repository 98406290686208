import { AppRoute } from '@/config/routes';
import { Container } from '@/ui/components/Container/Container';
import { useMediaQuery } from '@/ui/hooks/useMediaQuery';
import ActionButton from '@/ui/landing-v2/components/ActionButton/ActionButton';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { AwardComponent } from '../AboutUs/AwardComponent/AwardComponent';
import styles from './CreateWithAI.module.scss';

export const CreateWithAI = () => {
  const router = useRouter();
  const { isMobile, isDesktop } = useMediaQuery();
  const { t } = useTranslation(I18nNamespaces.Homepagev2);
  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.hero}>
          <h2 className={styles.title}>
            {isDesktop ? (
              <div>
                {t('home.createWithAi.title1')}
                <span> {t('home.createWithAi.title2')}</span>
              </div>
            ) : (
              <span>{t('home.createWithAi.title.mobile')}</span>
            )}
          </h2>
          <div className={styles.subtitleWrapper}>
            {isDesktop && (
              <>
                <h4 className={styles.subtitle}>
                  {t('home.createWithAi.subtitle')}
                  Nerdbord
                </h4>
                {/*<span className={styles.nerdbord}></span>*/}
              </>
            )}

            {isMobile && (
              <div className={styles.subtitle}>
                <div className={styles.mobileLogoWrapper}>
                  {t('home.createWithAi.subtitle')} Nerdbord
                </div>
              </div>
            )}
          </div>
          <span className={styles.description}>
            {t('home.createWithAi.description')}
          </span>
          <div className={styles.buttonWrapper}>
            <ActionButton
              onClick={() => router.push(AppRoute.workshops)}
              variant="tertiary"
            >
              {t('home.createWithAi.cta')}
            </ActionButton>
          </div>
          <Image
            className={styles.backgroundImageBig}
            width={791}
            height={374}
            src="/images/landing-v2/homepage-createwithai-background1.webp"
            alt={'background decoration image'}
          />
          <Image
            className={styles.backgroundImageSmall}
            width={322}
            height={322}
            src="/images/landing-v2/homepage-createwithai-background2.webp"
            alt={'background decoration image'}
          />
          <div className={styles.award1Wrapper}>
            <AwardComponent
              imageUrl={
                '/images/landing-v2/homepage-createwithai-award1.webp'
              }
              text={t('home.aboutUs.award1.text')}
            />
          </div>
          <div className={styles.award2Wrapper}>
            <AwardComponent
              imageUrl={
                '/images/landing-v2/homepage-createwithai-award2.webp'
              }
              text={t('home.aboutUs.award2.text')}
            />
          </div>{' '}
        </div>
      </Container>
    </div>
  );
};
