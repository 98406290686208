import { css } from '@emotion/css';

export enum Spacings {
  S4 = '4px',
  S8 = '8px',
  S12 = '12px',
  S16 = '16px',
  S20 = '20px',
  S22 = '22px',
  S24 = '24px',
  S32 = '32px',
  S36 = '36px',
  S42 = '42px',
  S44 = '44px',
  S48 = '48px',
  S54 = '54px',
  S56 = '56px',
  S80 = '80px',
}

export const MT24 = css({
  marginTop: Spacings.S24,
});
