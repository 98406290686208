export const MinusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path d="M17 12H7" stroke="#27272A" strokeLinecap="round" />
    </svg>
  );
};
