import { cache } from '@/lib/apollo-graphql/cache';
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { nanoid } from 'nanoid';
import { GetServerSidePropsContext, NextPageContext } from 'next';
import nextWithApollo from 'next-with-apollo';

export const IS_DEBUG = process.env.NODE_ENV !== 'production';
export const IS_DEV = IS_DEBUG;
export const IS_SERVER = typeof window === 'undefined';

export const ENV = process.env.NODE_ENV || 'development';

const wssUri = `wss://${process.env.NEXT_PUBLIC_WS_URI}/graphql`;
const wsUri = `ws://${process.env.NEXT_PUBLIC_WS_URI}/graphql`;

export function createSsrApolloClient(
  ctx: NextPageContext | GetServerSidePropsContext
) {
  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_BACKEND_URL + '/graphql',
    credentials: 'include',
    headers: {
      cookie: ctx.req?.headers['cookie'] || '',
    },
  });

  return new ApolloClient({
    ssrMode: IS_SERVER,
    link: httpLink,
    cache: new InMemoryCache({}),
  });
}

const withApollo = nextWithApollo(
  ({ initialState, headers, ...rest }: any) => {
    //@ts-ignore
    const httpLink = new HttpLink({
      uri: process.env.NEXT_PUBLIC_BACKEND_URL + '/graphql',
      headers,
      credentials: 'include',
    });

    //@ts-ignore
    return new ApolloClient({
      ssrMode: IS_SERVER,
      link: httpLink,
      cache: cache.restore(initialState || {}),
      // A hack to get ctx oin the page's props on the initial render
      // @ts-ignore
      defaultOptions: { ...rest },
    });
  },
  {
    render: ({ Page, props }) => {
      return (
        <ApolloProvider client={props.apollo}>
          <Page {...props} {...props.apollo.defaultOptions.ctx} />
        </ApolloProvider>
      );
    },
  }
);

export const withApolloWithSubscriptions = nextWithApollo(
  ({ initialState, headers, ...rest }) => {
    const clientId = nanoid();
    const wsLink = !IS_SERVER
      ? new WebSocketLink({
          uri: process.env.NODE_ENV === 'production' ? wssUri : wsUri,
          options: {
            reconnect: true,
            connectionParams: {
              clientId,
            },
          },
        })
      : null;

    //@ts-ignore
    const httpLink = new HttpLink({
      uri: process.env.NEXT_PUBLIC_BACKEND_URL + '/graphql',
      //@ts-ignore
      headers: {
        ...headers,
      },
      credentials: 'include',
    });

    /*
     * Only create a split link on the browser
     * The server can not use websockets and is therefore
     * always a http link
     */
    const splitLink = !IS_SERVER
      ? split(
          ({ query }) => {
            const definition = getMainDefinition(query);
            return (
              definition.kind === 'OperationDefinition' &&
              definition.operation === 'subscription'
            );
          },
          //@ts-ignore
          wsLink,
          httpLink
        )
      : httpLink;

    //@ts-ignore
    return new ApolloClient({
      ssrMode: IS_SERVER,
      link: splitLink,
      cache: cache.restore(initialState || {}),
      // A hack to get ctx oin the page's props on the initial render
      // @ts-ignore
      defaultOptions: { ...rest, clientId },
    });
  },
  {
    render: ({ Page, props }) => {
      return (
        <ApolloProvider client={props.apollo}>
          <Page
            {...props}
            {...props.apollo.defaultOptions.ctx}
            clientId={props.apollo.defaultOptions.clientId}
          />
        </ApolloProvider>
      );
    },
  }
);

export default withApollo;
