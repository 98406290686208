import { cx } from '@emotion/css';
import React, { PropsWithChildren } from 'react';

import { Theme } from '@/styles/themes';

import * as styles from './Button.styles';

export enum ButtonVariant {
  primary,
  primaryLarge,
  primaryPurple,
  primaryWhite,
  secondary,
  ternary,
  anchor,
  inverted,
  indigo,
  indigoBackground,
  orange,
  themed,
}

export enum ButtonSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

interface ButtonProps {
  icon?: React.ReactElement;
  type?: 'submit' | 'button';
  size?: ButtonSize;
  fullWidth?: boolean;
  disabled?: boolean;
  theme?: Theme;
  variant?: ButtonVariant;
  onClick?(e: React.MouseEvent): void;
}
export const Button = ({
  children,
  disabled,
  fullWidth = false,
  size = ButtonSize.large,
  variant = ButtonVariant.primary,
  onClick,
  icon,
  theme,
  ...rest
}: PropsWithChildren<ButtonProps>) => {
  const disabledClass = disabled && styles.buttonDisabled;

  return (
    <button
      className={cx(
        styles.button(fullWidth),
        buttonVariant(variant, size, disabled, theme),
        disabledClass
      )}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      {children} {icon}
    </button>
  );
};

const buttonSize = (size: ButtonSize) => {
  switch (size) {
    case ButtonSize.small:
      return styles.small;
    case ButtonSize.medium:
      return styles.medium;
    case ButtonSize.large:
      return styles.large;
    default:
      return styles.large;
  }
};

const buttonVariant = (
  variant: ButtonVariant,
  size: ButtonSize,
  disabled = false,
  theme?: Theme
) => {
  const btnSize = buttonSize(size);

  switch (variant) {
    case ButtonVariant.primary:
      return cx(
        styles.buttonPrimary,
        !disabled && styles.defaultButtonHover,
        btnSize
      );
    case ButtonVariant.primaryPurple:
      return cx(
        styles.buttonPrimaryPurple,
        !disabled && styles.defaultButtonHover,
        btnSize
      );
    case ButtonVariant.primaryLarge:
      return cx(
        styles.buttonPrimary,
        styles.buttonPrimaryLarge,
        !disabled && styles.defaultButtonHover,
        btnSize
      );
    case ButtonVariant.primaryWhite:
      return cx(
        styles.buttonPrimaryWhite,
        styles.defaultButtonHover,
        btnSize
      );
    case ButtonVariant.themed:
      return cx(
        theme ? styles.buttonThemed(theme) : undefined,
        theme ? styles.buttonThemedHover(theme) : undefined,
        btnSize
      );
    case ButtonVariant.secondary:
      return cx(
        styles.buttonSecondary,
        styles.defaultButtonHover,
        btnSize
      );
    case ButtonVariant.ternary:
      return cx(
        styles.buttonTernary,
        styles.defaultButtonHover,
        btnSize
      );
    case ButtonVariant.anchor:
      return styles.buttonAnchor(theme, disabled);
    case ButtonVariant.inverted:
      return styles.buttonInverted;
    case ButtonVariant.indigo:
      return styles.buttonIndigo;
    case ButtonVariant.indigoBackground:
      return styles.buttonIndigoBackground;
    case ButtonVariant.orange:
      return cx(
        styles.buttonOrangeBackground,
        !disabled && styles.defaultButtonHover,
        btnSize
      );
    default:
      return cx(
        styles.buttonPrimary,
        !disabled && styles.defaultButtonHover,
        btnSize
      );
  }
};
