export enum MediaQuery {
  Mobile,
  TabletVertical,
  TabletHorizontal,
  DesktopSmall,
  DesktopBig,
}

export const breakpoints = new Map([
  [MediaQuery.Mobile, 470],
  [MediaQuery.TabletVertical, 768],
  [MediaQuery.TabletHorizontal, 992],
  [MediaQuery.DesktopSmall, 1180],
  [MediaQuery.DesktopBig, 1420],
]);

export const mq = (mediaQuery: MediaQuery) =>
  `@media (max-width: ${breakpoints.get(mediaQuery)}px)`;
