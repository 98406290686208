import { Container } from '@/ui/components/Container/Container';
import { useMediaQuery } from '@/ui/hooks/useMediaQuery';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { SquareComponent } from './SquareComponent/SquareComponent';
import styles from './WhyParticipate.module.scss';
export const WhyParticipate = () => {
  const { isMobile, isDesktop } = useMediaQuery();
  const { t } = useTranslation(I18nNamespaces.Homepagev2);
  return (
    <div className={styles.container} id="why-participate">
      <Container>
        <div className={styles.header}>
          <h2 className={styles.title}>
            {t('home.whyParticipate.title')}
          </h2>
          <h4 className={styles.subtitle}>
            {t('home.whyParticipate.subtitle')}
          </h4>
        </div>
        <div className={styles.wrapper}>
          <Image
            className={styles.image}
            width={isDesktop ? 387 : 343}
            height={isDesktop ? 265 : 195}
            src="/images/landing-v2/homepage-why-participate-image1.webp"
            alt={'ai decoration image'}
          />
          <SquareComponent
            title={t('home.whyParticipate.challenge.title')}
            description={t(
              'home.whyParticipate.challenge.description'
            )}
          />
          <SquareComponent
            title={t('home.whyParticipate.experience.title')}
            description={t(
              'home.whyParticipate.experience.description'
            )}
          />
          {isMobile && (
            <Image
              className={styles.image}
              width={343}
              height={195}
              src="/images/landing-v2/homepage-why-participate-image2-mobile.webp"
              alt={'ai decoration image'}
            />
          )}
          <SquareComponent
            title={t('home.whyParticipate.learning.title')}
            description={t(
              'home.whyParticipate.learning.description'
            )}
          />
          <SquareComponent
            title={t('home.whyParticipate.fun.title')}
            description={t('home.whyParticipate.fun.description')}
          />
          {isDesktop && (
            <Image
              className={styles.image}
              width={387}
              height={265}
              src="/images/landing-v2/homepage-why-participate-image2.webp"
              alt={'ai decoration image'}
            />
          )}
        </div>
      </Container>
    </div>
  );
};
