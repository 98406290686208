import { InMemoryCache } from '@apollo/client';

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    ProjectEntity: {
      fields: {
        projectSchedules: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    ProgramEntity: {
      fields: {
        group: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        projectSchedules: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    GroupEntity: {
      fields: {
        users: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    GithubTaskDetailsEntity: {
      fields: {
        githubUserCredentials: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    ProjectTemplateEntity: {
      fields: {
        lessonTemplates: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    LessonTemplateEntity: {
      fields: {
        taskTemplates: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        _count: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    Mutation: {
      fields: {},
    },
    Query: {
      fields: {
        findMyTasks: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        findMyOrgGroups: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        findProjectTemplates: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        findMyLessons: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        findGroupTaskReviews: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        findProjectLessonTemplates: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        findUnresolvedOrgTaskReviews: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        findLessonTaskTemplates: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
  },
});
