import { PropsWithChildren } from 'react';
import * as styles from './EventHighlightComponent.styles';

interface EventHighlightComponentProps {
  text: string;
  imagePosition: 'left' | 'right';
}

export const EventHighlightComponent = ({
  text,
  imagePosition,
  children,
}: PropsWithChildren<EventHighlightComponentProps>) => {
  return (
    <div className={styles.container(imagePosition)}>
      {children}
      <div className={styles.text}>
        <p>{text}</p>
      </div>
    </div>
  );
};
